import * as React from 'react'
import { WpGql_Page } from '@/graphql-types'
import styled from 'styled-components'

// Components
import { ContentContainer } from '../Components/Common/ContentContainer'
import { HeroBanner } from '../Components/Common/HeroBanner'
import { BrandedTitle } from '../Components/Common/BrandedTitle'
import { RequestForm } from '../Components/Common/RequestForm'
import { DownloadCta, ShareCta } from '../Components/Common/CTAs'
import { ListWithCheckMark, ListWithMarker } from '../Components/Common/Lists'
import { Collapsible } from '../Components/Common/Collapsible'
import SupplierSlider from '../Components/Common/SupplierSlider'
import PostsSlider from '../Components/Common/CaseStudiesSlider'
import GenericBanner from '../Components/Common/GenericBanner'
import WeAreCertified from '../Components/Common/WeAreCertified'
import FocusCard from '../Components/Common/Team/FocusCard'
import { Buttons } from '../Components/Common/Styles/Interactions'
import { Paragraph } from '../Components/Common/Styles/Typography/Paragraph'
import { Flexed } from '../Components/Common/Styles/globalStyles'

interface ServiceProps {
	pageContext: {
		pageData: WpGql_Page
	}
}

const Wrapper = styled(ContentContainer)`
	h2 {
		margin-left: 0 !important;
		text-align: left;
		font-size: 40px;
		font-weight: 400;
	}

	.services--overview > :first-child {
		padding-right: 2vw;
	}

	.services__share {
		display: flex;
		margin-top: 32px;
		border-top: 1px solid ${props => props.theme.colors.casperGrey};
		border-bottom: 1px solid ${props => props.theme.colors.casperGrey};

		a {
			font-weight: 600;
		}

		> * {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 30px 0;

			img {
				margin-right: 0.5rem;
				max-width: 32px;
			}
		}
	}
`

const OurApproach = styled.section<{ backgroundImageUrl: string }>`
	position: relative;
	background: url('${props => props.backgroundImageUrl}'), rgba(29, 35, 64, 0.9);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	background-blend-mode: multiply;
	padding: 16px;

	@media (min-width: ${props => props.theme.screensizes.medium}) {
		padding: 64px 128px;
	}

    a {
        margin: auto;
    }

	h2 {
		margin-left: 0 !important;
		text-align: left;
		font-size: 40px;
		font-weight: 400;
	}

	p {
		margin: 30px 0 0;
		font-weight: 300;
		font-style: normal;
		color: ${props => props.theme.colors.greatlyGrey};

		@media (min-width: ${props => props.theme.screensizes.medium}) {
			padding-left: 16px;
		}
	}

	p:first-of-type {
		color: ${props => props.theme.colors.nearlyNavy};
		font-size: 20px;
		line-height: 24px;
		font-weight: 400;
		padding-left: 0;

		@media (min-width: ${props => props.theme.screensizes.medium}) {
			padding-right: 16px;
		}
	}

	.has-normal-font-size {
		font-size: 20px;
		line-height: 24px;
		font-weight: 400;
	}

	article {
		display: flex;
		flex-wrap: wrap;

		&:first-of-type {
			padding-bottom: 32px;
			border-bottom: 1px solid ${props => props.theme.colors.casperGrey};


			> :first-child,
			> :last-child {
				@media (min-width: ${props => props.theme.screensizes.medium}) {
					flex-basis: 50%;
				}
			}
		}

		&:last-of-type {
			padding-top: 32px;

			h3 {
				text-transform: uppercase;
				margin-bottom: 16px;
				font-size: 24px;
				font-family: var(--dinNarrow-font);
				font-weight: 600;
			}

			> :first-child {
				flex-basis: 100%;
				padding-bottom: 32px;

				@media (min-width: ${props => props.theme.screensizes.medium}) {
					flex-basis: 60%;
					padding-right: 32px;
					padding-bottom: 0;
				}
			}

			> :last-child {
				flex-basis: 100%;

				@media (min-width: ${props => props.theme.screensizes.medium}) {
					flex-basis: 40%;
				}
			}
		}
	}

	.has-extra-small-font-size {
		color: ${props => props.theme.colors.greatlyGrey};
	}

	.content-container {
		background: var(--mobyDickWhite);
		margin: 0 auto;
		padding: 48px 32px !important;
		max-width: 1180px;

		@media (min-width: ${props => props.theme.screensizes.large}) {
			padding: 64px 94px !important;
		}
	}
`;

const WhyUs = styled.aside`
	border: 1px solid ${props => props.theme.colors.casperGrey};
	padding: 32px;

	h3 {
		margin-bottom: 20px;
	}

	.button {
		margin-top: 30px
	}
`

const FAQs = styled.section`
	display: flex;
	flex-wrap: wrap-reverse;
	margin: 64px 0;

	> :first-child {
		flex-basis: 100%;
		margin-top: 48px;

		@media (min-width: ${props => props.theme.screensizes.medium}) {
			flex-basis: 40%;
			margin-top: 0;
			margin-left: 0;
		}

		ul {
			display: block;
			text-align: center;

			li {
				display: inline-block;
				width: 50%;
				padding: .78571rem;
				border: initial;
				margin: 0;
				max-width: 135px;

				@media (min-width: ${props => props.theme.screensizes.medium}) {
					max-width: 160px;
				}
			}
		}
	}

	> :last-child {
		flex-basis: 100%;

		@media (min-width: ${props => props.theme.screensizes.medium}) {
			flex-basis: 60%;
		}

		h2 {
			text-align: left;
			margin-left: 0 !important;
			margin-bottom: 16px;
		}

		p {
			margin-bottom: 32px;
			font-weight: 300;
		}
	}

	.collapsible {
		.title-container-alt { padding: 16px; }

		.title-inner {
			width: 100%;
			background-color: ${props => props.theme.colors.casperGrey};

			.title {
				margin-bottom: 0;
				font-size: 16px;
				text-transform: none;
				font-weight: 600;
			}
		}
	}
`

const ServiceTemplate: React.FC<ServiceProps> = (props) => {
	const { title, content, postServiceCustomFields, heroBannerOptions, teamFocus, share } = props?.pageContext?.pageData

	const checkHeroBannerOptions = () => {
		const options = Object.keys(heroBannerOptions || {}).length || 0

		let emptyOptions = 0;
		for (const key in heroBannerOptions) {
			if (heroBannerOptions[key] === null || heroBannerOptions[key] === "") emptyOptions += 1
		}

		return (emptyOptions === options) ? false : true
	}

	const checkApproachOptions = () => {
		const options = (postServiceCustomFields?.engineersApproach ? (Object?.keys(postServiceCustomFields.engineersApproach).length || 0) : 0) + (postServiceCustomFields.whyUs ? (Object?.keys(postServiceCustomFields.whyUs).length || 0) : 0)

		let emptyOptions = 0;

		const obj = { ...postServiceCustomFields.engineersApproach, ...postServiceCustomFields.whyUs }
		for (const key in obj) {
			if (obj[key] === null || obj[key] === "") emptyOptions += 1
		}

		return (emptyOptions === options || !postServiceCustomFields?.approach) ? false : true
	}

	return (
		<>
			{ checkHeroBannerOptions() && (
				<HeroBanner data={heroBannerOptions} />
			)}
			<Wrapper>
				<Flexed className="services--overview">
					<article>
						<BrandedTitle>Overview</BrandedTitle>
						{content && (
                            <Paragraph.Block text={ content }></Paragraph.Block>
						)}
                        { teamFocus?.members?.length && (
                            <FocusCard
                                members={teamFocus.members.map(member => (
                                    {
                                        image: member?.image?.sourceUrl || '',
                                        tagline: member?.tagline || '',
                                        name: member?.name || '',
                                    }
                                ))}
                            />
                        ) }
						{share?.links?.length && (
							<div className="services__share">
								{ share.links.map((link, index) => (
									link.type === 'Download' ? (
										<DownloadCta title={link.text} download={link.link} key={`cta-${index}`} />
									) : (
										<ShareCta title={link.text} href={link.link} key={`cta-${index}`} />
									)
								))}
							</div>
						)}
					</article>
					<RequestForm
						formId={ 1 }
						formTitle='Want to learn more?'
						formTagline='Complete the form below and a member of our team will be in touch shortly to discuss your requirements.'
						presetValues={{input_12: title}}
					/>
				</Flexed>
			</Wrapper>

			<SupplierSlider />

			{ checkApproachOptions() && (
				<OurApproach backgroundImageUrl={'http://pointsafedev.wpengine.com/wp-content/uploads/2021/04/iStock-1074890376@2x.png' || ''}>
					<ContentContainer>
						<BrandedTitle>Our Approach</BrandedTitle>
						{postServiceCustomFields?.approach && (
							<article dangerouslySetInnerHTML={{ __html: postServiceCustomFields.approach }}></article>
						)}
						<article>
							<aside>
								<h3>Our Engineers:</h3>
								{postServiceCustomFields?.engineersApproach && (
									<ListWithMarker listItems={postServiceCustomFields.engineersApproach} />
								)}
							</aside>
							<WhyUs>
								<h3>Why Us?</h3>
								{postServiceCustomFields?.whyUs && (
									<ListWithCheckMark listItems={postServiceCustomFields.whyUs} />
								)}
								<Buttons.Primary text={ 'Contact Us' } href={ '/contact-us/' } target={ '_self' } />
							</WhyUs>
						</article>
					</ContentContainer>
				</OurApproach>
			)}

			<ContentContainer>
				<FAQs>
					<WeAreCertified />
					<article>
						<BrandedTitle>FAQs</BrandedTitle>
						<p className="content-intro">
							If you can’t find the answer to your question below, a member of our team will be happy to help. Call us on 01704 330315.
						</p>
						{postServiceCustomFields?.faqs?.length && (
							postServiceCustomFields.faqs.map((faq, index) => (
								<Collapsible
									key={ index }
									title={faq.question}
									alt={true}
									altArrow={true}
								>
									<div className="content-inner">
										<div className="inner-content" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
									</div>
								</Collapsible>
							))
						)}
					</article>
				</FAQs>
			</ContentContainer>

			<PostsSlider />

			<GenericBanner />
		</>
	)
}

export default ServiceTemplate;